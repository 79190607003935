<template>
  <WidgetFrame @onResize="onResize" @onOrientationChange="onOrientationChange">
    <template v-slot:title>
      Skills
    </template>
    <template v-slot:content>
      <div v-if="!canView('PROJECT', ['TASK'])" class="center-text">
        {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
      </div>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else-if="noTasks">
        <div class='status-message'>No tasks in project.</div>
      </template>
      <template v-else>
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text" for="skillsType">
              {{$t('dashboard.widgets.view_by')}}
            </label>
          </div>
          <b-form-select id="skillsType" 
            :data-vv-as="$t('dashboard.widgets.view_by')"
            data-vv-name="dashboard.widgets.view_by"
            data-vv-delay="500"
            :options="optionTypes"
            text-field="label"
            value-field="key"
            v-model="type"
            />
        </div>
        <div class='chart-holder'>
          <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>          
        </div>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
  import {AgChartsVue} from 'ag-charts-vue';
  import WidgetFrame from "@/components/Dashboard/WidgetFrame";
  import settings from "@/_dashboardSettings";
  import { EventBus } from '@/helpers';
  import { skillService } from '@/services';
  import { tooltipRendererGenerator } from '@/helpers/ag-chart-tooltip-renderer';

  const maybePluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

  export default {
    name: 'ProjectSkillsWidget',
    components: {
      AgChartsVue,
      WidgetFrame
    },
    props: {
      profile:    { type: Object, required: true },
      project:    { type: Object, required: true }
    },
    watch: {
      type(newValue, oldValue) {
        if (oldValue != null) {
          this.saveSettings();
          this.buildData();
        }
      }
    },
    data() {
      return {
        orientation: null,
        width: 0,
        height: 0,
        loaded: false,
        noTasks: false,
        options: null,
        sourceData: [],
        title: "",
        type: null,
        optionTypes: [
          {key: 'number', label: 'Number of Tasks'},
          {key: 'percentage', label: 'Percentage of Total Work'},
        ]
      };
    },
    created() {
      EventBus.$on('theme-change', () => {
        if (this.loaded) {
          this.buildChart();
        }
      });

      // Use 'number' default type if profile doesn't have a valid key
      const pType = this.profile.optionType;
      this.type = !(this.optionTypes.map(t => t.key).includes(pType)) ? 'number' : pType;

      this.buildData();
    },
    beforeDestroy() {
      EventBus.$off('theme-change');
    },
    methods: {
      async buildData() {
        const self = this;
        this.sourceData = [];
        const skillData = await skillService.dashboardListNames(this.project.uuId).then(response => {
          return response.data;
        }).catch(e => {
          // eslint-disable-next-line
          console.error(e);
        });

        let totalWithSkills = 0;
        let totalDuration = 0;
        for (const skill of skillData) {
          totalWithSkills += skill.count;
          totalDuration += skill.totalDuration;
        }
        const skillCount = skillData.length;
        this.title = 
          maybePluralize(totalWithSkills, "task") +
          " using " + maybePluralize(skillCount, "skill");

        if (this.type == "number") {
          // Count how many tasks belong to each skill
          self.sourceData = skillData.map(s => { return { name: s.name, count: s.count }});
        } else if (this.type == "percentage") {
          self.sourceData = skillData.map(s => { return { name: s.name, count: ((s.totalDuration / totalDuration) * 100).toFixed(2) }});
        } else {
          console.error("Unknown skills list type"); // eslint-disable-line no-console
        }

        
        this.buildChart();
        this.loaded = true;
      },
      buildChart() {
        this.options = settings.getPieChartOptions(this.orientation, this.width, this.height, this.sourceData.length);
        this.options.title.text = this.title;
        this.options.series[0].data = this.sourceData;
        this.options.series[0].calloutLabelKey = 'name';
        this.options.series[0].angleKey = 'count';

        let contentLabel = this.$t('label.task_plural');
        let contentValueFormatter = null;
        if (this.type == "percentage") {
          contentLabel = this.$t('label.percentage');
          contentValueFormatter = (value) => (value == null || isNaN(value))? '0%' : `${parseFloat(value).toFixed(0).toString()}%`;
        }
        this.options.series[0].tooltip = {
          enabled: true,
          renderer: tooltipRendererGenerator({ title: 'datum["name"]', staticContentLabel: contentLabel, contentValue: 'datum.count', contentValueFormatter })
        }
      },
      saveSettings() {
        this.profile.optionType = this.type;
        this.$emit('saveWidget', this.profile);
      },
      onOrientationChange({orientation, width, height}) {
        // console.log(this.$options.name + ": Orientation change");
        this.orientation = orientation;
        this.onResize({width, height});
      },
      onResize({width, height}) {
        // console.log(this.$options.name + ": W: " + width + " H: " + height);
        this.width = width;
        this.height = height;
        if (this.loaded) {
          this.buildChart();
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .center-text {
    text-align: center;
    margin: auto;
  }
</style>